import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";
import {usePathname} from "next/navigation";

const MyComponent = () => {
    const svgRef = useRef(null);
    let tl = new TimelineLite();
    let t2 = new TimelineLite();
    const location = usePathname();
    gsap.registerPlugin(DrawSVGPlugin);
    useEffect(() => {
        const svg = svgRef.current;
        const paths = svg.querySelectorAll('#Path_8668');
        const name = svg.querySelectorAll('#Group_20776 path');
        tl.fromTo('.page-change', .6, {
            display: 'flex',
            opacity: 1,
        }, {
            duration: 1,
            delay: 1.5,
            opacity: 0,
            display: 'none'
        })
        // gsap.from('#Group_24047', {
        //     delay: .4,
        //     duration: 1,
        //     x: -100,
        //     ease: 'Power4.easeInOut'
        // })
        // gsap.from('#Group_24049', {
        //     delay: .4,
        //     duration: 1,
        //     x: 100,
        //     ease: 'Power4.easeInOut'
        // })
        gsap.set(paths, { opacity: 0, strokeDasharray: '100%', strokeDashoffset: '100%', height: 0 });
        gsap.set(name, { opacity: 0, strokeDasharray: '100%', strokeDashoffset: '100%' });

        name.forEach((name, index) => {
            gsap.to(name, {
                opacity: 1,
                strokeDashoffset: 0,
                duration: .5,
                delay: index * 0.2, // Adjust the delay between each path animation
                ease: 'power2.inOut',
            });
        });
        paths.forEach((path, index) => {
            gsap.to(path, {
                height: '100%',
                opacity: 1,
                strokeDashoffset: 0,
                duration: 2.5,
                delay: index * 0.5, // Adjust the delay between each path animation
                ease: 'power2.inOut',
            });
        });
    }, [location])

    // Group_24047
    // Group_24049

    return (
        <StyledComponent className={'page-change'}>
            <svg ref={svgRef} xmlns="http://www.w3.org/2000/svg" width="78.534" height="80" viewBox="0 0 78.534 80">
                <g id="Group_20678" data-name="Group 20678" transform="translate(-291.49 -95.276)">
                    <g id="Group_20659" data-name="Group 20659" transform="translate(294.91 95.276)">
                        <g id="Group_20654" data-name="Group 20654" transform="translate(0 0)">
                            <path id="Path_8665" data-name="Path 8665" d="M374.459,160.7l-71.7-.055,34.84-65.37Z" transform="translate(-302.764 -95.276)" fill="#fff"/>
                        </g>
                        <g id="Group_20656" data-name="Group 20656" transform="translate(6.36 8.251)">
                            <g id="Group_20655" data-name="Group 20655">
                                <path id="Path_8666" data-name="Path 8666" d="M382.112,175.064l-29.3-52.585-29.077,52.54,20.87.016-6.337-15.587,20.566.016-7.872,15.576Z" transform="translate(-323.731 -122.479)" fill="#f77e0b"/>
                            </g>
                        </g>
                        <g id="Group_20657" data-name="Group 20657" transform="translate(1.954 4.971)">
                            <path id="Path_8667" data-name="Path 8667" d="M309.576,169.555l-.37.676,67.5.052L362.29,144.449l-13.875-.011v.918l13.352.01c.245.444,12.67,22.715,13.382,24l-64.4-.049c.733-1.329,31.438-57.209,31.438-57.209l-.8-.444Z" transform="translate(-309.206 -111.664)"/>
                        </g>
                        <g id="Group_20658" data-name="Group 20658" transform="translate(22.654 11.611)">
                            <path id="Path_8668" data-name="Path 8668" d="M409.448,164.571l-.53-.954a2.5,2.5,0,0,0-1.169-.65l-16.26-.013c-1.79-.079-1.869-1.114-1.73-1.791a2.545,2.545,0,0,1,.186-.56l4-7.7,3.336-6.428,1.016-1.962-6.1-10.96-7.178,12.913-3.569,6.427-3.222,5.8a8.369,8.369,0,0,0-.741,2.461c-.439,3.756,3.5,3.917,3.5,3.917l14.373.011c1.816-.1,2.525.333,2.763.834a1.587,1.587,0,0,1-.11,1.3l-2.162,4.2-3.148,6.127-2.662,5.182,11.914.009,2.32-5.182,2.74-6.128L409.4,166.1c.015-.058.031-.12.04-.178A3.024,3.024,0,0,0,409.448,164.571Z" transform="translate(-377.448 -133.555)"/>
                        </g>
                    </g>
                    <g id="Group_20677" data-name="Group 20677" transform="translate(291.49 164.842)">
                        <path id="Path_8669" data-name="Path 8669" d="M356.625,325.173l-.372.4,1.028.349.314-.617Z" transform="translate(-336.608 -325.005)"/>
                        <path id="Path_8670" data-name="Path 8670" d="M492.855,325.268l-.421.4,1.088.234a2.611,2.611,0,0,0,.217-.407A8.853,8.853,0,0,1,492.855,325.268Z" transform="translate(-431.482 -325.071)"/>
                        <path id="Path_8671" data-name="Path 8671" d="M502.29,325.276l-.372.4-.9.255.089-.256Z" transform="translate(-437.459 -325.077)"/>
                        <path id="Path_8672" data-name="Path 8672" d="M510.68,325.28l-.372.4,1.066.257.1-.564Z" transform="translate(-443.935 -325.08)"/>
                        <path id="Path_8673" data-name="Path 8673" d="M519.165,325.288l-.372.4h-.857l.738-.359Z" transform="translate(-449.249 -325.085)"/>
                        <path id="Path_8674" data-name="Path 8674" d="M524.832,325.29l-.372.4,1.015.243s.356-.627.325-.61S524.832,325.29,524.832,325.29Z" transform="translate(-453.794 -325.087)"/>
                        <g id="Group_20676" data-name="Group 20676">
                            <g id="Group_20660" data-name="Group 20660" transform="translate(0.372)">
                                <path id="Path_8675" data-name="Path 8675" d="M297.846,328.419l-1.254-.281q-1.882-.415-1.879-1.557a1.162,1.162,0,0,1,.177-.632,1.378,1.378,0,0,1,.353-.385,2.117,2.117,0,0,1,1.324-.4,2.336,2.336,0,0,1,1.533.556,1.569,1.569,0,0,1,.4.453,4.907,4.907,0,0,1,.3.726.585.585,0,0,0,.022.069c0,.017.014.039.018.056s.022.069.04.116l.039-.038c.1-.157.3-.575.6-1.249a4.338,4.338,0,0,1,.62-1l-.085-.031a1.785,1.785,0,0,1-.795.22,3.892,3.892,0,0,1-.8-.137,8.708,8.708,0,0,0-2.026-.284,3.4,3.4,0,0,0-2.191.67,2.188,2.188,0,0,0-.454.47,2.339,2.339,0,0,0,.235,2.893,4.027,4.027,0,0,0,2.035.838,7.533,7.533,0,0,1,2.411.711,1.582,1.582,0,0,1,.813,1.356,1.317,1.317,0,0,1-.57,1.125l-.015.012a2.658,2.658,0,0,1-1.585.418,3.3,3.3,0,0,1-2.044-.589,2.409,2.409,0,0,1-.931-1.654l-.136-.036q-.091.276-.209.564c-.04.09-.08.183-.128.273a10.233,10.233,0,0,1-.953,1.587l.055.1a2,2,0,0,1,.938-.256,4.354,4.354,0,0,1,.869.112l.868.194a8.711,8.711,0,0,0,1.89.235,3.445,3.445,0,0,0,2.273-.755.337.337,0,0,0,.073-.067,2.306,2.306,0,0,0,.843-1.8Q300.527,329.008,297.846,328.419Z" transform="translate(-292.717 -324.62)"/>
                            </g>
                            <g id="Group_20661" data-name="Group 20661" transform="translate(9.697 7.231)">
                                <path id="Path_8676" data-name="Path 8676" d="M325.094,348.715a1.026,1.026,0,0,0-1.354,0,.9.9,0,0,0-.209.276.777.777,0,0,0-.073.347.813.813,0,0,0,.282.618.966.966,0,0,0,.672.259.984.984,0,0,0,.519-.138,1.081,1.081,0,0,0,.163-.122.8.8,0,0,0,.281-.614A.815.815,0,0,0,325.094,348.715Z" transform="translate(-323.458 -348.46)"/>
                            </g>
                            <g id="Group_20662" data-name="Group 20662" transform="translate(16.255 0.167)">
                                <path id="Path_8677" data-name="Path 8677" d="M354.394,333.106,353.15,330l-.095-.233a1.34,1.34,0,0,1,.472-.165c.081-.016.171-.023.271-.034s.185-.01.289-.009c.062,0,.171.007.312.018a4.468,4.468,0,0,1-.594-1.387,2.3,2.3,0,0,1-.279.228l-.245.193c-.014.009-.048.034-.106.072l-.172.1-.3.146-1.209-2.951,0-.009.386-.4.386-.4-3.424-.005.4.4.284.279.1.1-1.013,2.539-.1.2-.129.3c-.052-.035-.1-.066-.145-.1v0c-.1-.066-.187-.136-.266-.2l-.331-.288a1.481,1.481,0,0,1-.121-.1l0,0a4.612,4.612,0,0,1-.232.52,1.375,1.375,0,0,1-.088.158,3.9,3.9,0,0,1-.312.485,2.18,2.18,0,0,1,1.134.374l-.03.1a10.211,10.211,0,0,1-1.233,2.621,3.568,3.568,0,0,1-.494.555,4.367,4.367,0,0,1-1.184.8h.843l1.61,0h.488l.767,0c-.056-.061-.1-.1-.13-.131a3.2,3.2,0,0,1-.589-.688,1.3,1.3,0,0,1-.14-.633,2.918,2.918,0,0,1,.2-.924l.245-.692.03-.09c.085-.239.155-.422.214-.551l.326.314.163.157.074.083a15.951,15.951,0,0,1,.985,1.282l.092.139a5.967,5.967,0,0,1,.517-.563,6.043,6.043,0,0,1,.988-.938l.146.3.054.164.825,1.983-.409.316-.62.476.147,0,.412,0h2.259l.933,0Zm-3.346-2.906.023.052c-.183.152-.356.3-.511.456a.65.65,0,0,1-.053.055l-.16.178c-.01.013-.024.025-.034.038a.025.025,0,0,0-.014.008c-.046-.052-.069-.083-.074-.087l-.342-.435a9.849,9.849,0,0,0-.97-1.05l.912-2.2.268-.653.208.523.128.268,1.014,2.5C351.3,329.968,351.173,330.082,351.048,330.2Z" transform="translate(-345.079 -325.169)"/>
                            </g>
                            <g id="Group_20663" data-name="Group 20663" transform="translate(31.924 0.128)">
                                <path id="Path_8678" data-name="Path 8678" d="M404.621,333.064v-3.353l.52-.456v0l.51-.447-4.9,0-.251,0a1.078,1.078,0,0,1-.123-.006c-.08-.01-.122-.036-.126-.079s.034-.055.1-.067l.043-.008.138-.028c.157-.032.24-.146.239-.348a.394.394,0,0,0-.146-.307.515.515,0,0,0-.339-.13.594.594,0,0,0-.449.2.747.747,0,0,0-.146.238.863.863,0,0,0-.042.253c0,.241-.038.59.14.713a.79.79,0,0,0,.269.1l.216.011c.146.032.285-.092.483-.093h.336l2.074,0,0,.365v.193l0,3.533h-1.2q-3.53,0-3.525-3.948a4.44,4.44,0,0,1,.757-2.749.164.164,0,0,1,.024-.038,2.6,2.6,0,0,1,2.178-.993,2.189,2.189,0,0,1,1.581.57,2.352,2.352,0,0,1,.632,1.122,2.88,2.88,0,0,1,.065.31c.013.069.026.138.034.211l1.377-2.494a2.321,2.321,0,0,1-.946.213,7.03,7.03,0,0,1-1.321-.256,7.155,7.155,0,0,0-1.738-.25,4.315,4.315,0,0,0-2.923,1.067,3.946,3.946,0,0,0-.488.5,4.424,4.424,0,0,0-.925,2.884,4.149,4.149,0,0,0,1.491,3.354,3.876,3.876,0,0,0,1.245.749,4.852,4.852,0,0,0,1.559.213l.753,0,2.42,0h1.364Z" transform="translate(-396.737 -325.042)"/>
                            </g>
                            <g id="Group_20664" data-name="Group 20664" transform="translate(42.193 0.064)">
                                <path id="Path_8679" data-name="Path 8679" d="M437.3,331.847a12.028,12.028,0,0,1-1.279-2.256c-.041-.086-.073-.173-.109-.259a4.036,4.036,0,0,0,1.782-.844.4.4,0,0,0,.048-.038,1.768,1.768,0,0,0-.352-2.989,4.928,4.928,0,0,0-2.6-.628,7.94,7.94,0,0,0-3.782.93.042.042,0,0,1-.014,0l.056.022a1.568,1.568,0,0,1,.2.08,2.045,2.045,0,0,1,.329.151,1.684,1.684,0,0,1,.178.089l-.005,3.34a1.986,1.986,0,0,1-.37.028,5.1,5.1,0,0,1-.52-.029c-.01.03-.02.047-.025.064-.06.184-.091.286-.1.3s-.015.034-.02.051c-.02.06-.04.107-.06.153v0a1.546,1.546,0,0,0-.071.235l.081-.029a4.284,4.284,0,0,1,1.083-.177l0,.356,0,.167v2.288l-.37.312-.587.494h3.291l-.871-.807,0-2.683,0-.352.086-.016a14.693,14.693,0,0,0,1.762-.261.628.628,0,0,1,.036.082l.077.194a.745.745,0,0,0,.05.1l.05.121.032.07a18.151,18.151,0,0,1,1.323,4.459l.039.22.11-.067a10.632,10.632,0,0,1,1.415-.708c.086-.033.172-.066.253-.1q.251-.092.5-.173l.114-.032A8.39,8.39,0,0,1,437.3,331.847Zm-1.119-3.473c-.024.025-.053.042-.077.063a5.109,5.109,0,0,1-2.174.77l-.081.012h-.208a2.646,2.646,0,0,1-.422.036l0-3.4v-.266a3.122,3.122,0,0,1,1.331-.259,2.63,2.63,0,0,1,1.62.493,1.508,1.508,0,0,1,.644,1.246A1.585,1.585,0,0,1,436.18,328.374Z" transform="translate(-430.59 -324.832)"/>
                            </g>
                            <g id="Group_20665" data-name="Group 20665" transform="translate(51.946 0.105)">
                                <path id="Path_8680" data-name="Path 8680" d="M469.746,326.222a3.626,3.626,0,0,0-2.884-1.255,3.894,3.894,0,0,0-3,1.208,2.06,2.06,0,0,0-.136.166,4.674,4.674,0,0,0-.981,3.1,4.247,4.247,0,0,0,1.623,3.623,4.094,4.094,0,0,0,2.528.833,3.7,3.7,0,0,0,2.612-.935,3.082,3.082,0,0,0,.246-.241,4.516,4.516,0,0,0,1.067-3.152A4.938,4.938,0,0,0,469.746,326.222Zm-1.179,6.113a2.13,2.13,0,0,1-.479.559,1.913,1.913,0,0,1-1.277.427q-2.472,0-2.468-3.738a5.458,5.458,0,0,1,.737-3.179,2.063,2.063,0,0,1,1.778-.861,1.986,1.986,0,0,1,1.736.885,7.672,7.672,0,0,1-.025,5.907Z" transform="translate(-462.744 -324.967)"/>
                            </g>
                            <g id="Group_20666" data-name="Group 20666" transform="translate(61.323 0.194)">
                                <path id="Path_8681" data-name="Path 8681" d="M499.081,325.267l.476.4.228.192.457.38-.03,4.4a4.506,4.506,0,0,1-.369,2.253,1.265,1.265,0,0,1-.323.317,2.308,2.308,0,0,1-1.342.332,2.282,2.282,0,0,1-1.555-.427,1.872,1.872,0,0,1-.461-1.445l-.018-1.052.006-4.37v-.069l.534-.473,0,0,.029-.029.448-.4-3.462-.006h-.047l.028.022.457.376.252.206.275.228,0,4.512a4.031,4.031,0,0,0,.614,2.633,4.651,4.651,0,0,0,4.881.332,1.6,1.6,0,0,0,.328-.33,1.894,1.894,0,0,0,.338-.871,12.035,12.035,0,0,0,.089-1.742l0-4.263,0-.125.787-.572.029-.025.514-.374Z" transform="translate(-493.659 -325.261)"/>
                            </g>
                            <g id="Group_20667" data-name="Group 20667" transform="translate(70.366 0.202)">
                                <path id="Path_8682" data-name="Path 8682" d="M530.5,325.829a6.26,6.26,0,0,0-3.025-.54l-3.329,0,.433.4.229.21.242.223-.005,3.323-.118-.006a2.339,2.339,0,0,0-.251.013,1.473,1.473,0,0,0-1.149.678.37.37,0,0,0-.054.076l.074.1a2,2,0,0,1,1.266-.367,1.045,1.045,0,0,1,.231.034l0,.335,0,.18,0,2.726-.37.286-.648.5a.058.058,0,0,0-.014.008l.147,0,.256,0h2l.2,0h.852l-.9-.748,0-2.889,0-.4h.909a9.609,9.609,0,0,0,2.5-.236,2.593,2.593,0,0,0,.937-.492,1.86,1.86,0,0,0,.285-.283,1.915,1.915,0,0,0,.44-1.264A2.073,2.073,0,0,0,530.5,325.829Zm-1.076,3.092c-.038.025-.077.05-.12.076a4.47,4.47,0,0,1-2.152.392h-.208a.451.451,0,0,1-.095.007l-.189,0-.095-.01v-3.014l0-.378q.5-.031.853-.026a3.473,3.473,0,0,1,1.957.425,1.553,1.553,0,0,1,.609,1.37A1.319,1.319,0,0,1,529.425,328.921Z" transform="translate(-523.472 -325.285)"/>
                            </g>
                            <path id="Path_8683" data-name="Path 8683" d="M312.211,332.245l-.372.4.311-.687Z" transform="translate(-305.667 -329.731)"/>
                            <path id="Path_8684" data-name="Path 8684" d="M295.746,345.509l.372-.4-.011.573L296.1,346s-.32,0-.319-.043S295.746,345.509,295.746,345.509Z" transform="translate(-294.455 -338.896)"/>
                            <path id="Path_8685" data-name="Path 8685" d="M352.258,335.421l-.372.4.089.457a3.658,3.658,0,0,0,.5-.279C352.461,335.968,352.258,335.421,352.258,335.421Z" transform="translate(-333.566 -332.145)"/>
                            <path id="Path_8686" data-name="Path 8686" d="M373.706,338.906l-.82-.346-.09.644.539.1Z" transform="translate(-348.134 -334.332)"/>
                            <path id="Path_8687" data-name="Path 8687" d="M376.067,353.3l.314.092-.372.4-.969-.317.184-.319Z" transform="translate(-349.697 -344.499)"/>
                            <path id="Path_8688" data-name="Path 8688" d="M366.242,353.767l.652-.071-.5.381-.521.088Z" transform="translate(-343.309 -344.876)"/>
                            <path id="Path_8689" data-name="Path 8689" d="M354.418,353.147l-.372.4-.886-.39.006-.346Z" transform="translate(-334.454 -344.259)"/>
                            <path id="Path_8690" data-name="Path 8690" d="M344.219,353.539l-.372.4.95-.138.426-.423Z" transform="translate(-327.966 -344.653)"/>
                            <path id="Path_8691" data-name="Path 8691" d="M422.661,353.575l-.416.4-.976-.268.058-.3.908.014Z" transform="translate(-381.904 -344.673)"/>
                            <path id="Path_8692" data-name="Path 8692" d="M418.836,332.992l-.372.4.066-.658.133-.282.21.051Z" transform="translate(-379.949 -330.075)"/>
                            <path id="Path_8693" data-name="Path 8693" d="M439.4,353.485s-.286.4-.372.4a6.988,6.988,0,0,1-.81-.416l.108-.2.585.009Z" transform="translate(-393.714 -344.578)"/>
                            <path id="Path_8694" data-name="Path 8694" d="M430.411,353.8l-.372.4.912-.239-.236-.233Z" transform="translate(-388.014 -344.901)"/>
                            <path id="Path_8695" data-name="Path 8695" d="M430.643,340.052l-.372.4.243.25.554-.555Z" transform="translate(-388.175 -335.371)"/>
                            <path id="Path_8696" data-name="Path 8696" d="M454.52,353.691l-.372.4-1.371-.258s.279-.38.445-.333S454.52,353.691,454.52,353.691Z" transform="translate(-403.854 -344.736)"/>
                            <path id="Path_8697" data-name="Path 8697" d="M431.073,327.743l-.372.4.906.014.143-.4a.926.926,0,0,0-.26-.085A3.926,3.926,0,0,0,431.073,327.743Z" transform="translate(-388.474 -326.743)"/>
                            <path id="Path_8698" data-name="Path 8698" d="M423.17,337.444l-.372.4-.914.348.007-.349,0-.233Z" transform="translate(-382.332 -333.554)"/>
                            <path id="Path_8699" data-name="Path 8699" d="M533.994,353.69l-.374.424-.576-.26-.2-.32.944.057Z" transform="translate(-459.636 -344.764)"/>
                            <path id="Path_8700" data-name="Path 8700" d="M524.387,353.706l-.372.4s.546-.167.593-.193a1.739,1.739,0,0,0,.208-.245s0-.093-.04-.1A2.319,2.319,0,0,0,524.387,353.706Z" transform="translate(-453.484 -344.782)"/>
                            <g id="Group_20776" data-name="Group 20776" transform="translate(0 0.398)">
                                <path id="Path_8701" data-name="Path 8701" d="M296.619,329.73l-1.255-.281q-1.881-.415-1.879-1.557a1.248,1.248,0,0,1,.53-1.018s.014-.008.019-.012a2.116,2.116,0,0,1,1.3-.392,2.358,2.358,0,0,1,1.533.556,1.573,1.573,0,0,1,.4.453,5.635,5.635,0,0,1,.344.851c.009.03.022.069.04.117l.038-.038s0,0,.005-.008c.059-.093.147-.268.27-.524.005-.013.01-.021.015-.034.089-.187.193-.413.311-.682a4.484,4.484,0,0,1,.62-.995l-.085-.031a1.792,1.792,0,0,1-.8.22,3.62,3.62,0,0,1-.8-.137,8.711,8.711,0,0,0-2.026-.284,3.4,3.4,0,0,0-2.191.671.637.637,0,0,0-.082.072,2.083,2.083,0,0,0-.765,1.671,2.105,2.105,0,0,0,.628,1.619,4.028,4.028,0,0,0,2.035.838,7.527,7.527,0,0,1,2.411.711,1.581,1.581,0,0,1,.813,1.356,1.34,1.34,0,0,1-.2.727,1.414,1.414,0,0,1-.387.41,2.659,2.659,0,0,1-1.585.418,3.3,3.3,0,0,1-2.044-.589,2.316,2.316,0,0,1-.83-1.206,2.208,2.208,0,0,1-.074-.319.605.605,0,0,1-.026-.129l-.137-.036a8.809,8.809,0,0,1-1.291,2.423l.055.1a2,2,0,0,1,.938-.256,4.357,4.357,0,0,1,.869.112l.868.194a8.7,8.7,0,0,0,1.89.235,3.445,3.445,0,0,0,2.273-.755,2.437,2.437,0,0,0,.444-.465,2.237,2.237,0,0,0,.471-1.405Q299.3,330.319,296.619,329.73Z" transform="translate(-291.49 -325.931)" fill="#f77e0b"/>
                                <path id="Path_8702" data-name="Path 8702" d="M323.867,350.027a.965.965,0,0,0-.677-.255,1,1,0,0,0-.514.134,1.054,1.054,0,0,0-.164.122.823.823,0,0,0,0,1.241,1.013,1.013,0,0,0,1.354,0,.9.9,0,0,0,.209-.276.742.742,0,0,0,.073-.338A.815.815,0,0,0,323.867,350.027Z" transform="translate(-312.906 -342.54)" fill="#f77e0b"/>
                                <path id="Path_8703" data-name="Path 8703" d="M351.048,334.806l-.519.4,3.75,0-.561-.4-.252-.18-.3-.211-1.34-3.339a1.273,1.273,0,0,1,.467-.164,3.527,3.527,0,0,1,.565-.043c.062,0,.17.007.312.018a2.706,2.706,0,0,1-.229-.407,2.563,2.563,0,0,1-.141-.3v0a4.2,4.2,0,0,1-.224-.673,2.236,2.236,0,0,1-.279.227l-.245.194c-.014.009-.048.034-.105.071l-.173.1-.3.146-1.214-2.96.377-.389.4-.41h-.014l-2.206,0-.426,0-.777,0,.786.776-1.013,2.535v0l-.1.2-.129.3a4.95,4.95,0,0,1-.411-.307l-.093-.083-.224-.192-.014-.013a1.392,1.392,0,0,1-.126-.109,4.7,4.7,0,0,1-.632,1.162,2.183,2.183,0,0,1,1.134.374l-.03.1a10.194,10.194,0,0,1-1.233,2.621c-.039.055-.078.106-.121.157a4.046,4.046,0,0,1-.767.731c-.029.021-.062.042-.1.067a4.737,4.737,0,0,1-.694.4l3.708.005c-.056-.061-.1-.1-.13-.131-.1-.1-.191-.187-.265-.27a2.975,2.975,0,0,1-.227-.265.963.963,0,0,1-.1-.152,1.3,1.3,0,0,1-.14-.633,2.925,2.925,0,0,1,.2-.924l.245-.692c.1-.282.18-.5.244-.64l.158.153.167.162.163.157.074.083a15.82,15.82,0,0,1,.985,1.282l.134.2c.1-.158.214-.31.331-.459a5.963,5.963,0,0,1,.517-.563,5.436,5.436,0,0,1,.616-.54l.146.3.054.164.788,1.9.036.087-.485.37Zm-1.342-3.184a.64.64,0,0,1-.053.055l-.16.178c-.01.013-.024.025-.034.038l-.126.131a.681.681,0,0,0-.082.085l-.106.114a.317.317,0,0,1-.073.076c-.046-.052-.069-.082-.074-.087l-.342-.434a9.865,9.865,0,0,0-.97-1.05l1.18-2.854.1.255.1.268.128.268.991,2.449.023.052C350.033,331.318,349.86,331.466,349.706,331.622Z" transform="translate(-327.969 -326.314)" fill="#f77e0b"/>
                                <path id="Path_8704" data-name="Path 8704" d="M404.319,335.112l-.476-.381-.21-.166-.238-.188v-3.353l.371-.329.659-.578h-4.9l-.142,0h-.109c-.166,0-.246-.03-.249-.09,0-.022.01-.034.034-.047a.191.191,0,0,1,.062-.021l.181-.036a.264.264,0,0,0,.222-.216.542.542,0,0,0,.017-.133.036.036,0,0,0,0-.022.37.37,0,0,0-.142-.285.513.513,0,0,0-.339-.13.537.537,0,0,0-.223.044.649.649,0,0,0-.226.16.726.726,0,0,0-.189.491c0,.489.37.735,1.109.734h.336l2.074,0,0,.365v.193l0,3.134,0,.4h-1.2q-3.53,0-3.525-3.948a4.436,4.436,0,0,1,.781-2.787,2.4,2.4,0,0,1,.348-.36,2.638,2.638,0,0,1,1.83-.633,2.209,2.209,0,0,1,1.58.569,2.641,2.641,0,0,1,.732,1.643l.338-.609.059-.111.98-1.775a2.326,2.326,0,0,1-.946.213,7.016,7.016,0,0,1-1.321-.257,7.172,7.172,0,0,0-1.738-.25,4.312,4.312,0,0,0-2.923,1.067c-.039.034-.077.067-.116.106a4.27,4.27,0,0,0-1.3,3.281A4.15,4.15,0,0,0,397,334.163a3.8,3.8,0,0,0,1.246.749,4.845,4.845,0,0,0,1.559.213l.753,0,3.741,0h.042Z" transform="translate(-363.959 -326.226)" fill="#f77e0b"/>
                                <path id="Path_8705" data-name="Path 8705" d="M437.595,334.843c-.13-.1-.256-.218-.386-.345a9.776,9.776,0,0,1-1.135-1.34,11.833,11.833,0,0,1-1.388-2.515c.167-.04.328-.085.481-.139a3.665,3.665,0,0,0,1.349-.743,2.127,2.127,0,0,0,.324-.36,1.711,1.711,0,0,0,.346-1.038,1.851,1.851,0,0,0-1.022-1.591,4.926,4.926,0,0,0-2.6-.628,8,8,0,0,0-2.836.506,3.473,3.473,0,0,0-.329.133,6.854,6.854,0,0,0-.631.3l.056.022a4.5,4.5,0,0,1,.709.32l0,2.971,0,.369a1.976,1.976,0,0,1-.37.029H430.1c-.066,0-.147,0-.237-.008-.066-.01-.142-.015-.227-.021-.01.03-.02.047-.025.064-.06.184-.091.286-.1.3a3.122,3.122,0,0,0-.15.444l.081-.029a4.257,4.257,0,0,1,1.083-.177l0,.356,0,.167v2.288l-.438.371,0,0-.043.034-.471.4,3.291,0-.428-.4-.238-.218-.2-.192v-3.035l.085-.012.285-.034a12.686,12.686,0,0,0,1.477-.231.713.713,0,0,1,.036.082l.077.194a.725.725,0,0,0,.05.1l.05.121.032.069a18.122,18.122,0,0,1,1.323,4.46l.039.219.11-.067a10.232,10.232,0,0,1,2.168-.98l.114-.033C437.758,334.975,437.674,334.913,437.595,334.843Zm-5.6-4.276,0-3.662a1.94,1.94,0,0,1,.372-.132,3.241,3.241,0,0,1,.959-.123,2.6,2.6,0,0,1,1.62.489,1.508,1.508,0,0,1,.645,1.246,1.591,1.591,0,0,1-.263.9,1.833,1.833,0,0,1-.449.461,5.109,5.109,0,0,1-2.174.769l-.081.012h-.208A2.614,2.614,0,0,1,431.992,330.568Z" transform="translate(-387.543 -326.079)" fill="#f77e0b"/>
                                <path id="Path_8706" data-name="Path 8706" d="M468.519,327.534a3.626,3.626,0,0,0-2.884-1.255,3.939,3.939,0,0,0-2.765.975c-.082.072-.159.152-.237.232a4.645,4.645,0,0,0-1.117,3.267,4.248,4.248,0,0,0,1.623,3.623,4.1,4.1,0,0,0,2.527.833,3.67,3.67,0,0,0,2.859-1.175.971.971,0,0,0,.126-.157,4.55,4.55,0,0,0,.942-3A4.94,4.94,0,0,0,468.519,327.534Zm-4.3-.216a2.12,2.12,0,0,1,1.406-.463,2,2,0,0,1,1.736.885,7.67,7.67,0,0,1-.025,5.907c-.034.056-.073.111-.107.162a1.914,1.914,0,0,1-1.649.825q-2.472,0-2.468-3.738C463.115,329.108,463.483,327.912,464.224,327.318Z" transform="translate(-409.943 -326.173)" fill="#f77e0b"/>
                                <path id="Path_8707" data-name="Path 8707" d="M501.027,326.58h-.171l-1.889,0h-1.113l1.161.971-.03,4.4a5,5,0,0,1-.32,2.172.486.486,0,0,1-.049.081,1.9,1.9,0,0,1-1.665.648,2.286,2.286,0,0,1-1.555-.427,1.873,1.873,0,0,1-.461-1.445l-.018-1.052.007-4.439.371-.329.645-.574h-.076l-.743,0-1.463-.006h-1.226l1.012.831,0,4.512a4.033,4.033,0,0,0,.614,2.633,3.5,3.5,0,0,0,2.726.871,2.922,2.922,0,0,0,2.483-.868.52.52,0,0,0,.044-.068,1.9,1.9,0,0,0,.294-.8,12.042,12.042,0,0,0,.089-1.742l.006-4.388.37-.273Z" transform="translate(-431.482 -326.378)" fill="#f77e0b"/>
                                <path id="Path_8708" data-name="Path 8708" d="M529.273,327.139a6.252,6.252,0,0,0-3.025-.54l-2.178,0h-1.151l.9.83,0,2.932,0,.391-.118-.006a1.7,1.7,0,0,0-1.028.293,1.525,1.525,0,0,0-.425.474l.074.1a2,2,0,0,1,1.267-.367,1.425,1.425,0,0,1,.231.033l0,.335,0,.18v2.614l0,.112-.346.269,0,0h-.009l-.154.122-.514.4,0,0h3.457l-.48-.4-.177-.149-.242-.2v-3.289l.374,0h.535a9.624,9.624,0,0,0,2.5-.236,2.448,2.448,0,0,0,1.222-.776l.087-.115a1.906,1.906,0,0,0,.353-1.149A2.072,2.072,0,0,0,529.273,327.139Zm-3.841,3.569-.095-.01,0-3.392.37-.02q.27-.009.483-.005a3.51,3.51,0,0,1,1.957.425,1.552,1.552,0,0,1,.609,1.37,1.452,1.452,0,0,1-.189.757,1.324,1.324,0,0,1-.492.473,4.466,4.466,0,0,1-2.153.392h-.208a.453.453,0,0,1-.095.007Z" transform="translate(-452.251 -326.394)" fill="#f77e0b"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    height: 100vh;
    position: fixed;
    //background-color: #2C7EC3;
    //background: linear-gradient(180deg, rgba(19, 126, 195, 1) 50%, rgba(47, 191, 255, 1) 100%);
    background-color: #137ec4;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9999999999999999999;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 180px;
        height: auto;
    }

    @media (max-width: 600px) {
        svg {
            width: 200px;
        }
    }
`;

export default MyComponent;
