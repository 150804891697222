export async function getFooterApi() {
    const response = await fetch(
        `https://bestinbd.com/projects/web/2309SAG/api/get-req-data/settings-data`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}
