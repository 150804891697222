"use client";
import{useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {usePathname} from "next/navigation";
import Link from "next/link";
import {getFooterApi} from "@/api/footer";

const MyMenu = ({bgColor, textColor}) => {

    const location = usePathname();
    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const slug = location.split('/')[1];


    useEffect(() => {
        setActiveMenuItem(location);
    }, [location]);


    //Sticky Menu behavior
    const [scrollUp, setScrollUp] = useState(false)
    const [scrollDown, setScrollDown] = useState(false)

    useEffect(() => {
        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        let lastScroll = 0;
        let howMuchScroll = 100;

        const handleScroll = () => {
            if (!document.querySelector('.menu').classList.contains('menu-active')) {
                let currentScroll = window.pageYOffset;

                if (currentScroll <= howMuchScroll) {
                    setScrollUp(false)
                    setScrollDown(false)

                    return;
                }
                if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                    setScrollDown(true)
                    setScrollUp(false)
                } else if (currentScroll < lastScroll && !body.classList.contains(scrollUp)) {
                    setScrollUp(true)
                    setScrollDown(false)
                }
                lastScroll = currentScroll;
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    //toggle menu action
    useEffect(() => {
        const menuClickHandler = () => {
            document.body.classList.toggle('menu-open');
        };

        const hamClick = document.querySelector('.menu-desktop__hamburger');
        hamClick.addEventListener('click', menuClickHandler);

        return () => {
            hamClick.removeEventListener('click', menuClickHandler);
        };
    }, []);


    //close Hamburger menu when click on menu items
    useEffect(() => {
        const handleMenuItemClick = (event) => {
            const menuItems = document.querySelectorAll('.menu-mobile .menu-mobile__items li');
            // Add a 0.5-second delay before closing the menu
            setTimeout(() => {
                // Remove menu-open class from body
                document.body.classList.remove('menu-open');
                // Remove menu-open class from body
                document.body.classList.remove('menu-open');
            }, 2000);

        };

        const menuItems = document.querySelectorAll('.menu-mobile .menu-mobile__items li');
        menuItems.forEach(item => {
            item.addEventListener('click', handleMenuItemClick);
        });

        return () => {
            menuItems.forEach(item => {
                item.removeEventListener('click', handleMenuItemClick);
            });
        };
    }, []);


    const [getFooter, setFooterData] = useState(null);

    useEffect(() => {
        const fetchAboutData = async () => {
            const data = await getFooterApi();
            setFooterData(data);
        };

        fetchAboutData();
    }, []);



    return (
        <>
            <StyledComponent className={`menu ${scrollDown ? "scroll-down" : ""} ${scrollUp ? "scroll-up" : ""}`}
            bgColor={bgColor} textColor={textColor}>
                <Container>
                    <div className={'menu-desktop'}>
                        <div className={'menu-desktop__logo'}>
                            <Link href={'/'} prefetch={true}>
                                <img src={'/images/static/logo.svg'} alt={'logo'}/>
                            </Link>
                        </div>
                        <div className={'menu-desktop__items'}>
                            <ul>
                                <li className={activeMenuItem === '/' ? 'active' : ''}>
                                    <Link href={'/'} prefetch={true}>
                                        Home
                                    </Link>
                                </li>
                                <li className={activeMenuItem === '/about-us' ? 'active' : ''}>
                                    <Link href={'/about-us'} prefetch={true}>
                                        About
                                    </Link>
                                </li>
                                <li className={activeMenuItem === '/business-verticals' || slug.startsWith('business-verticals')  ? 'active' : ''}>
                                    <Link href={'/business-verticals'} prefetch={true}>
                                        Business Verticals
                                    </Link>
                                </li>
                                <li className={activeMenuItem === '/csr' ? 'active' : ''}>
                                    <Link href={'/csr'} prefetch={true}>
                                        CSR
                                    </Link>
                                </li>
                                <li className={activeMenuItem === '/news-events' || slug.startsWith('news-events') ? 'active' : ''}>
                                    <Link href={'/news-events'} prefetch={true}>
                                        News & Events
                                    </Link>
                                </li>
                                <li className={activeMenuItem === '/career' ? 'active' : ''}>
                                    <Link href={'/career'} prefetch={true}>
                                        Career
                                    </Link>
                                </li>
                                <li className={activeMenuItem === '/contact' ? 'active' : ''}>
                                    <Link href={'/contact'} prefetch={true}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={'menu-desktop__hamburger'}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </Container>
            </StyledComponent>
            <StyledMobile>
                <div className={'menu-mobile'}>
                    <ul className={'menu-mobile__items'}>
                        <li className={activeMenuItem === '/' ? 'active' : ''}>
                            <Link href={'/'} prefetch={true}>
                                Home
                            </Link>
                        </li>
                        <li className={activeMenuItem === '/about-us' ? 'active' : ''}>
                            <Link href={'/about-us'} prefetch={true}>
                                About
                            </Link>
                        </li>
                        <li className={activeMenuItem === '/business-verticals' ? 'active' : ''}>
                            <Link href={'/business-verticals'}>
                                Business Verticals
                            </Link>
                        </li>
                        <li className={activeMenuItem === '/csr' ? 'active' : ''}>
                            <Link href={'/csr'}>
                                CSR
                            </Link>
                        </li>
                        <li className={activeMenuItem === '/news-events' ? 'active' : ''}>
                            <Link href={'/news-events'}>
                                News & Events
                            </Link>
                        </li>
                        <li className={activeMenuItem === '/career' ? 'active' : ''}>
                            <Link href={'/career'}>
                                Career
                            </Link>
                        </li>
                        <li className={activeMenuItem === '/contact' ? 'active' : ''}>
                            <Link href={'/contact'}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                    <ul className={'socials'}>
                        {
                            getFooter?.data?.facebook &&
                            <li>
                                <a href={getFooter?.data?.facebook} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.498" height="14"
                                         viewBox="0 0 7.498 14">
                                        <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
                                              d="M8.616,7.875l.389-2.534H6.574V3.7A1.267,1.267,0,0,1,8,2.328H9.108V.171A13.478,13.478,0,0,0,7.146,0,3.093,3.093,0,0,0,3.835,3.41V5.341H1.609V7.875H3.835V14H6.574V7.875Z"
                                              transform="translate(-1.609)" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                        }
                        {
                            getFooter?.data?.instagram &&
                            <li>
                                <a href={getFooter?.data?.instagram} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.003" height="14"
                                         viewBox="0 0 14.003 14">
                                        <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram"
                                              d="M7,5.648a3.589,3.589,0,1,0,3.589,3.589A3.584,3.584,0,0,0,7,5.648Zm0,5.923A2.334,2.334,0,1,1,9.331,9.238,2.338,2.338,0,0,1,7,11.571ZM11.571,5.5a.837.837,0,1,1-.837-.837A.835.835,0,0,1,11.571,5.5Zm2.377.85a4.143,4.143,0,0,0-1.131-2.933A4.17,4.17,0,0,0,9.884,2.287c-1.156-.066-4.62-.066-5.776,0A4.164,4.164,0,0,0,1.175,3.415,4.157,4.157,0,0,0,.044,6.348c-.066,1.156-.066,4.62,0,5.776a4.143,4.143,0,0,0,1.131,2.933,4.176,4.176,0,0,0,2.933,1.131c1.156.066,4.62.066,5.776,0a4.143,4.143,0,0,0,2.933-1.131,4.17,4.17,0,0,0,1.131-2.933c.066-1.156.066-4.617,0-5.773Zm-1.493,7.013A2.363,2.363,0,0,1,11.125,14.7,15.429,15.429,0,0,1,7,14.976,15.549,15.549,0,0,1,2.871,14.7,2.363,2.363,0,0,1,1.54,13.364a15.429,15.429,0,0,1-.281-4.127A15.549,15.549,0,0,1,1.54,5.111,2.363,2.363,0,0,1,2.871,3.78,15.429,15.429,0,0,1,7,3.5a15.549,15.549,0,0,1,4.127.281,2.363,2.363,0,0,1,1.331,1.331,15.429,15.429,0,0,1,.281,4.127A15.42,15.42,0,0,1,12.455,13.364Z"
                                              transform="translate(0.005 -2.238)" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                        }
                        {
                            getFooter?.data?.linkedin &&
                            <li>
                                <a href={getFooter?.data?.linkedin} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                        <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in"
                                              d="M2.686,12H.2V3.989H2.686ZM1.441,2.9A1.448,1.448,0,1,1,2.882,1.442,1.453,1.453,0,0,1,1.441,2.9ZM12,12H9.515V8.1c0-.929-.019-2.121-1.293-2.121-1.293,0-1.492,1.01-1.492,2.054V12H4.244V3.989H6.631V5.082h.035A2.614,2.614,0,0,1,9.019,3.788C11.537,3.788,12,5.446,12,7.6V12Z"
                                              transform="translate(0 0)" fill="#fff"/>
                                    </svg>

                                </a>
                            </li>
                        }
                        {
                            getFooter?.data?.youtube &&
                            <li>
                                <a href={getFooter?.data?.youtube} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9.844"
                                         viewBox="0 0 14 9.844">
                                        <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube"
                                              d="M14.757,6.04A1.759,1.759,0,0,0,13.52,4.794,41.572,41.572,0,0,0,8.05,4.5a41.572,41.572,0,0,0-5.47.294A1.759,1.759,0,0,0,1.343,6.04,18.454,18.454,0,0,0,1.05,9.432a18.454,18.454,0,0,0,.293,3.392A1.733,1.733,0,0,0,2.58,14.049a41.572,41.572,0,0,0,5.47.294,41.572,41.572,0,0,0,5.47-.294,1.733,1.733,0,0,0,1.238-1.226,18.454,18.454,0,0,0,.293-3.392,18.454,18.454,0,0,0-.293-3.392ZM6.618,11.513V7.35l3.659,2.082L6.618,11.513Z"
                                              transform="translate(-1.05 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                        }
                    </ul>
                </div>
            </StyledMobile>
        </>
    );
};

const StyledComponent = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    background: ${props => props?.bgColor ? props?.bgColor : "transparent"};
    backdrop-filter: blur(5px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    transition: all .6s ease;

    .container {
        padding: 0;

        @media (max-width: 991px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .menu-desktop {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        &__items {
            ul {
                display: flex;
                gap: 30px;

                li {
                    a {
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 500;
                        letter-spacing: 1.4px;
                        color: ${props => props?.textColor ? props?.textColor : "#ffffff"};
            text-transform: uppercase;
          }
          
          &.active{
            a{
              color: #2C7EC3 !important;
            }
          }
        }
      }
    }

    &__hamburger{
      display: none !important;
      opacity: 0 !important;
      width: 50px;
      height: 50px !important;
      background-color: #2C7EC3 !important;
      border-radius: 10px !important;
      cursor: pointer;
      
      span{
        display: block;
        width: 20px;
        height: 2px;
        background-color: #ffffff;
        margin-bottom: 5px;
        border-radius: 5px;
        transition: 0.3s transform ease-in-out;
        &:last-child{
          margin-bottom: 0 !important;
        }
      }
    }
    
    @media(max-width: 991px){
      &__items{
        display: none;
        opacity: 0;
      }
      &__hamburger{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        opacity: 1 !important;
      }
    }
  }
  &.scroll-down {
    transform: translate3d(0, -160px, 0);
  }

  &.scroll-up {
    background-color: #ffffff;
    
    .menu-desktop{
      &__items{
        ul{
          li{
            a{
              color: #000000;
            }
          }
        }
      }
    }
  }
`;

const StyledMobile = styled.section`{

  .menu-mobile{
    
    position: fixed;
    top: 110px;
    display: none;
    opacity: 0;
    height: 0;
    width: 100% !important;
    z-index: 999;
    background-color: #2C7EC3;
    transition: 0.6s height ease-in-out;
    overflow: hidden;

    @media(max-width: 991px){
      display: flex;
      opacity: 1;
      gap: 30px
    }
    
    ul{
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 60px;
      width: 100%;
      
      li{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          width: 0px;
          height: 2px;
          top: 50%;
          left: 0;
          background-color: #ffffff;
          transform: translateY(-50%);
          transform-origin: left;
          transition: width .5s ease-out;
        }
        a{
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          letter-spacing: 2px;
          color: #fff;
          text-transform: uppercase;
          transition: 0.3s padding ease-in-out;
          
          &:hover{
            color: #fff !important;
          }
        }
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }

        &.active{
          a{
            padding-left: 55px;
            transition: 0.3s padding ease-in-out;
            &:hover{
              color: #ffffff !important;
            }
          }
          
          &:after{
            width: 40px;
            transition: width .5s ease-out;
          }
        }
      }
    }
    
    .socials{
      position: fixed;
      bottom: 30px;
      display: none !important;
      justify-content: center;
      align-items: center;
      gap: 15px;
      opacity : 0;
      overflow: hidden;
      //transition: opacity 1s ease-in-out, visibility 0.5s linear 1s;
      a{
        font-size: unset !important;
        line-height: unset !important;
        letter-spacing: unset !important;
      }
      li{
        height: 30px;
        width: 30px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        cursor: pointer;
        
        a{
          padding: 0;
          margin: 0;
        }
        svg{
          margin-top: -2px !important;
        }
      }
      
      @media(max-width: 991px){
        display: flex !important;
      }
    }
  }
}`

export default MyMenu;
