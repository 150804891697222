"use client";
import {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Img from "./Img";
import Link from "next/link";
import {getAboutApi} from "@/api/about";
import {getFooterApi} from "@/api/footer";

const Footer = () => {

    const [getFooter, setFooterData] = useState(null);

    useEffect(() => {
        const fetchAboutData = async () => {
            const data = await getFooterApi();
            setFooterData(data);
        };

        fetchAboutData();
    }, []);



    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])

    const year = new Date().getFullYear();

    //refactor
    const arrayOfNumbers = getFooter?.data?.office_phone ? getFooter?.data?.office_phone?.split(',') : '';
    const arrayOfEmail = getFooter?.data?.contact_email ? getFooter?.data?.contact_email?.split(',') : '';
    const dhakaOffice = getFooter?.data?.office_location;
    const ctgOffice = getFooter?.data?.office_location_two;
    const footerbg = getFooter?.data?.footer_background;

    return (
        <StyledComponent offset={offset}>
            <Img src={`https://bestinbd.com/projects/web/2309SAG/${footerbg}`}/>
            <Container>
                <Row>
                    <Col md={4} className={'left'}>
                        <div className="contact">
                            {
                                arrayOfNumbers && arrayOfNumbers?.length > 0 &&
                                arrayOfNumbers?.map((number, index) => (
                                    <h5 key={index}>
                                        <a href={`tel:${number}`} target={"_blank"} rel={'nofollow'}>
                                            {`+${number}`}
                                        </a>
                                    </h5>
                                ))
                            }
                            {
                                arrayOfEmail && arrayOfEmail?.length > 0 &&
                                arrayOfEmail?.map((email, k) => (
                                    <h5 key={k}>
                                        <a href={`mailto:${email}`} target={"_blank"} rel={'nofollow'}>
                                            {email}
                                        </a>
                                    </h5>
                                ))
                            }
                        </div>
                        <ul className={'socials'}>
                            <li className={'hover'}>
                                <a href={getFooter?.data?.facebook} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.498" height="14" viewBox="0 0 7.498 14">
                                        <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M8.616,7.875l.389-2.534H6.574V3.7A1.267,1.267,0,0,1,8,2.328H9.108V.171A13.478,13.478,0,0,0,7.146,0,3.093,3.093,0,0,0,3.835,3.41V5.341H1.609V7.875H3.835V14H6.574V7.875Z" transform="translate(-1.609)" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                            <li className={'hover'}>
                                <a href={getFooter?.data?.instagram} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.003" height="14" viewBox="0 0 14.003 14">
                                        <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M7,5.648a3.589,3.589,0,1,0,3.589,3.589A3.584,3.584,0,0,0,7,5.648Zm0,5.923A2.334,2.334,0,1,1,9.331,9.238,2.338,2.338,0,0,1,7,11.571ZM11.571,5.5a.837.837,0,1,1-.837-.837A.835.835,0,0,1,11.571,5.5Zm2.377.85a4.143,4.143,0,0,0-1.131-2.933A4.17,4.17,0,0,0,9.884,2.287c-1.156-.066-4.62-.066-5.776,0A4.164,4.164,0,0,0,1.175,3.415,4.157,4.157,0,0,0,.044,6.348c-.066,1.156-.066,4.62,0,5.776a4.143,4.143,0,0,0,1.131,2.933,4.176,4.176,0,0,0,2.933,1.131c1.156.066,4.62.066,5.776,0a4.143,4.143,0,0,0,2.933-1.131,4.17,4.17,0,0,0,1.131-2.933c.066-1.156.066-4.617,0-5.773Zm-1.493,7.013A2.363,2.363,0,0,1,11.125,14.7,15.429,15.429,0,0,1,7,14.976,15.549,15.549,0,0,1,2.871,14.7,2.363,2.363,0,0,1,1.54,13.364a15.429,15.429,0,0,1-.281-4.127A15.549,15.549,0,0,1,1.54,5.111,2.363,2.363,0,0,1,2.871,3.78,15.429,15.429,0,0,1,7,3.5a15.549,15.549,0,0,1,4.127.281,2.363,2.363,0,0,1,1.331,1.331,15.429,15.429,0,0,1,.281,4.127A15.42,15.42,0,0,1,12.455,13.364Z" transform="translate(0.005 -2.238)" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                            <li className={'hover'}>
                                <a href={getFooter?.data?.linkedin} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                        <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M2.686,12H.2V3.989H2.686ZM1.441,2.9A1.448,1.448,0,1,1,2.882,1.442,1.453,1.453,0,0,1,1.441,2.9ZM12,12H9.515V8.1c0-.929-.019-2.121-1.293-2.121-1.293,0-1.492,1.01-1.492,2.054V12H4.244V3.989H6.631V5.082h.035A2.614,2.614,0,0,1,9.019,3.788C11.537,3.788,12,5.446,12,7.6V12Z" transform="translate(0 0)" fill="#fff"/>
                                    </svg>

                                </a>
                            </li>
                            <li className={'hover'}>
                                <a href={getFooter?.data?.youtube} target={'_blank'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9.844" viewBox="0 0 14 9.844">
                                        <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M14.757,6.04A1.759,1.759,0,0,0,13.52,4.794,41.572,41.572,0,0,0,8.05,4.5a41.572,41.572,0,0,0-5.47.294A1.759,1.759,0,0,0,1.343,6.04,18.454,18.454,0,0,0,1.05,9.432a18.454,18.454,0,0,0,.293,3.392A1.733,1.733,0,0,0,2.58,14.049a41.572,41.572,0,0,0,5.47.294,41.572,41.572,0,0,0,5.47-.294,1.733,1.733,0,0,0,1.238-1.226,18.454,18.454,0,0,0,.293-3.392,18.454,18.454,0,0,0-.293-3.392ZM6.618,11.513V7.35l3.659,2.082L6.618,11.513Z" transform="translate(-1.05 -4.5)" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                        <div className="copyright">
                            <p>© {year} SA Group. All Rights Reserved.</p>
                            {/*<p>Design & Developed by <a href={'https://dcastalia.com/'} target={'_blank'}>Dcastalia</a></p>*/}
                        </div>
                    </Col>
                    <Col md={4} className={'middle'}>
                        <div className={'list'}>
                            <ul>
                                <li>
                                    <Link href={'/'}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/about-us'}>
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/business-verticals'}>
                                        Business Verticles
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/csr'}>
                                        CSR
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/news-events'}>
                                        News & Events
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/career'}>
                                        Career
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/contact'}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4} className={'right'}>
                        <div className={'address'}>
                            <div className={'address__single'}>
                                <p>Dhaka Office</p>
                                <a href={`https://www.google.com/maps?q=${dhakaOffice}`} target={'_blank'}>{dhakaOffice}</a>
                            </div>
                            <div className={'address__single'}>
                                <p>Chattogram Office</p>
                                <a href={`https://www.google.com/maps?q=${ctgOffice}`} target={'_blank'}>{ctgOffice}</a>
                            </div>
                        </div>
                    </Col>
                    <Col md={12} className={'copyright-mobile'}>
                        <p>© {year} SA Group. All Rights Reserved.</p>
                        {/*<p>Design & Developed by <a href={'https://dcastalia.com/'} target={'_blank'}>Dcastalia</a></p>*/}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    position: relative;
    overflow: hidden;
    //padding-top: calc(500 / 1366 * 100%);
    //max-height: 500px;
  
    &:after{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(19 126 195 / 0.9);
    }
    
    .global-image{
        &:after{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(1 191 255 / 0.9);
            z-index: -1;
        }
    }
  
    .container{
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 9999;
      .left{
        padding-bottom: 50px;
        padding-top: 100px;
        border-right: 1px solid rgba(255 255 255 / 0.5);
        .contact{
          h5{
            padding-bottom: 20px;
            a{
              color: #ffffff;
                &:hover{
                    color: unset !important;
                }
            }
            &:last-child{
              padding-bottom: 0;
            }
          }
        }
        .socials{
          margin-top: 40px;
          display: flex;
          //justify-content: center;
          align-items: center;
          gap: 15px;
          overflow: hidden;
          //transition: opacity 1s ease-in-out, visibility 0.5s linear 1s;
          a{
            font-size: unset !important;
            line-height: unset !important;
            letter-spacing: unset !important;
              &:hover{
                  color: unset !important;
              }
          }
          li{
            height: 30px;
            width: 30px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            cursor: pointer;

            a{
              padding: 0;
              margin: 0;
                &:hover{
                    color: unset !important;
                }
            }
            svg{
              margin-top: -2px !important;
            }
            
            &:hover{
              svg{
                position: relative;
                z-index: 999;
              }
            }
          }

          @media(max-width: 991px){
            display: flex !important;
          }
        }
        .copyright{
          margin-top: 100px;
          p{
            color: #ffffff !important;
            a{
              color: #ffffff;
                &:hover{
                    color: unset !important;
                }
            }
          }
        }
      }
      .middle{
        padding-top: 100px;
        border-right: 1px solid rgba(255 255 255 / 0.5);
        .list{
          ul{
            li{
              padding-bottom: 20px;
              a{
                text-transform: uppercase;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: #ffffff !important;
                  &:hover{
                      color: unset !important;
                  }
              }
              &:last-child{
                padding-bottom: 0;
              }
            }
          }
        }
      }
      
      .right{
        padding-top: 100px;
        .address{
          &__single{
            padding-bottom: 40px;
            p{
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #ffffff;
              padding-bottom: 20px;
            }
            
            a{
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #ffffff;
                &:hover{
                    color: unset !important;
                }
            }
            
            &:last-child{
              padding-bottom: 0;
            }
          }
        }
      }
      .copyright-mobile{
        display: none;
        opacity: 0;
      }
    }
  
  @media(max-width: 767px){
    .container{
      .left{
        padding-top: 40px;
        border-right: none;
        border-bottom: 1px solid rgba(255 255 255 / 0.5);
        .copyright{
          display: none;
        }
      }
      
      .middle{
        padding-top: 40px;
        padding-bottom: 30px;
        border-right: none;
        border-bottom: 1px solid rgba(255 255 255 / 0.5);
      }
      .right{
        padding-top: 40px;
        padding-bottom: 30px;
        border-right: none;
        border-bottom: 1px solid rgba(255 255 255 / 0.5);
      }
      
      .copyright-mobile{
        display: block;
        opacity: 1;
        padding-top: 40px;
        padding-bottom: 30px;
        p{
          color: #ffffff !important;
          a{
            color: #ffffff;
          }
        }
      }
    }
  }
`;

export default Footer;
