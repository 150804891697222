import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

    ${createCSS()}
    #root {
        min-height: 100vh;
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        width: 0.3rem; /* Adjusted the width to be smaller */
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: ${hover}; /* Color of the thumb */
        border: 1px solid transparent; /* Reduced the border size */
        border-radius: 9px; /* Optionally keep the border-radius */
        background-clip: content-box;
    }


    body {
      font-family: 'Work Sans', Arial, Helvetica, freesans, sans-serif !important;
      font-style: normal;
      margin: 0;
      color: ${text};
      padding: 0;
      overflow-x: hidden;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      min-height: 100vh;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
        background-color: #ffffff;
    }
    .mt-100{
        padding-top: 90px;
    }

    a {
      transition: color .3s ease;
      text-decoration: none;

      &:hover {
        color: ${hover} !important;
        text-decoration: none;
        outline: none;
        box-shadow: none;
      }

      &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        color: ${text};
      }
    }

    ::selection {
      background: ${hover};
      color: #FFF;
    }

    p, a, h4, h3, h5, h6 {
      color: ${text};
      font-weight: 400;
      margin: 0;
    }

    h1, h2 {
        //font-family: ${title};
      margin: 0;
    }


    h1{
      font-family: ${title};
      font-size: 48px;
      line-height: 52px;
      font-weight: 400;
      @media(max-width: 767px){
        font-size: 42px;
        line-height: 50px;
      }
    }

    h2{
      font-family: ${title};
      font-size: 42px;
      line-height: 50px;
      font-weight: 400;
      letter-spacing: -1.26px;
      @media(max-width: 767px){
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.96px;
      }
    }

    h3{
      font-family: ${title};
      font-size: 28px;
      line-height: 36px;
      font-weight: 400;
      letter-spacing: -0.84px;
      @media(max-width: 767px){
        font-size: 24px;
        line-height: 30px;
      }
    }

    h4{
      font-family: ${title};
      font-size: 28px;
      line-height: 36px;
      font-weight: 400;
      letter-spacing: -0.84px;
      @media(max-width: 767px){
        font-size: 24px;
        line-height: 30px;
        letter-spacing: unset;
      }
    }

    h5{
      font-family: ${title};
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      @media(max-width: 767px){
        font-size: 18px;
        line-height: 28px;
      }
    }

    h6{
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 1.4px;
    }



    ul {
      margin: 0;
      padding: 0
    }

    li {
      list-style: none;
    }

    img {
      max-width: 100%;
      object-fit: contain;
    }


    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
      outline: none;
      box-shadow: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 1px solid rgba(0, 0, 0, 0);
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
      transition: background-color 5000s ease-in-out 0s;
    }


    table {
      width: 100%;
    }

    form div {
      position: relative;
    }

    .form-control {
      box-shadow: none;
      outline: 0;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }

    .p-0 {
      padding: 0 !important;
    }

    .pl-0 {
      padding-left: 0;
    }

    .pr-0 {
      padding-right: 0;
    }

    .pt-200 {
      padding-top: 200px;
      @media (max-width: 767px) {
        padding-top: 100px;
      }
    }

    .pb-200 {
      padding-bottom: 200px;
      @media (max-width: 767px) {
        padding-bottom: 100px;
      }
    }

    .pt-150 {
      padding-top: 150px;
      @media (max-width: 767px) {
        padding-top: 80px;
      }
    }

    .pb-150 {
      padding-bottom: 150px;
      @media (max-width: 767px) {
        padding-bottom: 80px;
      }
    }

    .pt-120 {
      padding-top: 120px;
      @media (max-width: 767px) {
        padding-top: 80px;
      }
    }

    .pb-120 {
      padding-bottom: 120px;
      @media (max-width: 767px) {
        padding-bottom: 80px;
      }
    }

    .pb-130 {
      padding-bottom: 130px;
      @media (max-width: 767px) {
        padding-bottom: 100px;
      }
    }

    .pt-100 {
      padding-top: 100px;
      @media (max-width: 767px) {
        padding-top: 60px;
      }
    }

    .pb-100 {
      padding-bottom: 100px;
      @media (max-width: 767px) {
        padding-bottom: 60px;
      }
    }

    .pt-80 {
      padding-top: 80px;
      @media (max-width: 767px) {
        padding-top: 40px;
      }
    }

    .pb-80 {
      padding-bottom: 80px;
      @media (max-width: 767px) {
        padding-bottom: 40px;
      }
    }


    .MuiDrawer-paper {
      width: 500px !important;
      padding: 20px;
      @media (max-width: 767px) {
        width: 100% !important;
      }
    }

    .swiper-button-disabled {
      opacity: 0 !important;
    }

    @media (min-width: 1500px) {
      .container {
        //min-width: 1366px;
        min-width: 85%;
        margin: auto;
      }
    }

    @media (max-width: 1199px) and (min-width: 768px) {
      .container, .container-lg, .container-md, .container-sm {
        max-width: 90%;
        margin: auto;
      }
    }


    @media (max-width: 767px) {
      .container, .container-sm {
        max-width: 100%;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


    //react select
    .css-yk16xz-control, .css-1pahdxg-control {
      height: 50px;
      border-radius: 0 !important;
      padding-left: 5px;
      font-size: 16px;
      outline: none !important;
      border-color: #D9D9D9 !important;
      box-shadow: none !important;

      .css-1wa3eu0-placeholder {
        font-weight: 300;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.5);
        outline: none;
      }

      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
        margin-right: 10px;
      }
    }

    .css-2613qy-menu {
      border-radius: 0 !important;
      margin-top: 0 !important;
    }

    //animation class


    .info-window {
      max-width: 200px;
    }

    .gm-style-iw {
      border-radius: 0 !important;
    }

    .swiper-pagination-bullet {
      outline: none;
    }

    .css-nmuc1a-menu {
      z-index: 5 !important;
    }


    .map-info__img {
      img {
        height: 100px;
        margin-bottom: 12px;
        object-fit: cover;
      }
    }

    .map-info__content {
      h4 {
        font-size: 20px;
      }

      p {
        margin-bottom: 5px;
      }
    }

    .overlay {
      position: fixed;
      height: 100vh;
      width: 100%;
      //background-color: rgba(0,0,0,0.5);
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 9;
      display: none;

      &.show {
        display: block;
      }
    }

    .form-control.has-error {
      border-color: #dc004e !important;
    }

    .has-error .find-retainer-filter__control {
      border-color: #dc004e !important;
    }

    //preloader
    .content-loader {
      position: absolute;
      height: 70%;
      width: 70%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .loading-before-submit {
      position: fixed;
      height: 100vh;
      width: 100%;
      bottom: 0;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.65);
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 40px;
      }
    }


    .swiper-slide {
      height: auto;
    }

    .slick-slide {
      div {
        outline: none !important
      }
    }

    button, button:active, button:focus, button:focus-visible {
      outline: none !important;
      box-shadow: none !important;
    }


    .hover {
      position: relative;
      overflow: hidden;

      span {
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: ${hover};
        transition: all .5s ease;
        border-radius: 19px;
      }

      &:hover {
        &:after {
          height: 100%;
          width: 100%;
        }
      }
    }


    .modal-backdrop {
      background-color: rgb(34 31 31 / 90%);
      min-width: 100%;
      //z-index: 9999;

      &.show {
        opacity: 1;
      }
    }


    .valid {
      color: ${hover};
      position: absolute;
      font-size: 12px;
      top: 44px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }


    .form-control:disabled {
      background-color: transparent;
    }

    @media (max-width: 600px) {
      .prevent-overflow {
        overflow: hidden;
        height: 100vh;
      }
    }

    .Toastify__toast-container {
      z-index: 99999999;
    }

    .mobile-menu {
      #fb-root, .fb_reset {
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }

    .slick-slide {
      -webkit-transform: translate3d(0, 0, 0);
    }

    .reveal, .revealFast {
      overflow: hidden;
      height: 100%;
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;

      img {
        transform-origin: left;
      }

      .global-image {
        background: transparent;
      }
    }

    //calender
    ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
      border-radius: 0 !important;
    }

    //video modal 
    .modal-video-close-btn:before, .modal-video-close-btn:after {
      background-color: ${hover};
    }

    .page-loader {
      position: fixed;
      background-color: rgb(36, 24, 67);
      width: 100vw;
      z-index: 999999999;
      overflow: hidden;
      //opacity: 0;
      //height: 100vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      //left: 0; //width: 0;
      //transition: all 2.6s ease;
      //.anim-logo {
      //  width: 130px;
      //  overflow: hidden;
      //  height: fit-content;
      //  position: absolute;
      //  left: 0;
      //  right: 0;
      //  top: 0;
      //  bottom: 0;
      //  opacity: 0;
      //  margin: auto;
      //  z-index: 2;
      //
      //  img {
      //    height: 55px;
      //  }
      //}

      //.hide-logo {
      //  background-color: #010A1A;
      //  width: 50%;
      //  height: 100vh;
      //  top: 0;
      //  left: 0;
      //  right: 0;
      //  margin: auto;
      //  position: absolute;
      //  z-index: 999;
      //}

      .pre-loader__img {
        //position: fixed;
        height: 100px;
        width: 100px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(36, 24, 67);
        z-index: 99999999;

        //flex: 1;
        margin: auto;
        opacity: 0;

        img {
          display: block;
        }

        .top {
          height: 8px;
        }

        .bottom {
          height: 30px;
          margin-top: 5px;
          animation: flip 1s linear infinite;
        }

        @keyframes flip {
          0% {
            transform: rotateY(0deg)
          }
          100% {
            transform: rotateY(180deg)
          }
        }
      }

    }

    //------------------------animation
    .split-parent {
      overflow: hidden;
    }

    .split-child {
      overflow: hidden;
    }

    .reveal {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        transform-origin: left;
      }

      .global-image {
        background: transparent;
      }
    }


    //page transition
    .page-transition {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: ${hover};
      z-index: 999999999;
      display: none;
      opacity: 0;
      //display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        height: 80px;
        opacity: 0;
      }
    }


    //mobile menu
    .menu-open{
      overflow: hidden !important;
      .scroll-up{
        background-color: #2C7EC3 !important;
      }
      .menu{
        background-color: #2C7EC3;
        .menu-desktop{
          &__hamburger{
            background-color: #ffffff !important;
            span{
              background-color: #2C7EC3 !important;

              &:first-child{
                margin-bottom: 3px;
                transform: rotate(45deg);
                transition: 0.3s transform ease-in-out;
              }
              &:nth-child(2){
                display: none;
                transition: 0.3s transform ease-in-out;
              }
              &:last-child{
                transform: rotate(310deg);
                margin-top: -5px;
                transition: 0.3s transform ease-in-out;
              }
            }
          }
        }
      }
      .menu-mobile{
        height: 100svh !important;
        transition: 0.6s height ease-in-out;

        .socials{
          display: flex !important;
          opacity: 1 !important;
          transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
          transition-delay: 0.5s;
        }
      }

    }

    //Modal Video Popup
    .modal_video_popup {
      .modal-video {
        background: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .modal-dialog {
        &:focus-visible {
          outline: none !important;
        }
      }

      .for-close {
        display: none;
      }

      .modal-dialog, .modal-content {
        background: transparent;
      }
    }

    #smooth-wrapper{
        width: 100%;
        overflow-x: hidden !important;
        will-change: transform;
    }
    //for shaking 
    #smooth-content {
      will-change: transform;
    }


    //video modal
    .modal-video {
      background-color: transparent;
      height: 100vh;
      z-index: 99999;

      .modal-dialog {
        height: 100vh;
        background-color: transparent;
        min-width: 100%;
        margin: 0;
      }

      .modal-body {
        height: 100vh;
      }

      .modal-content {
        background-color: transparent;

      }

      iframe {
        height: 60vh;
        width: 60vw;
        margin: auto;
        position: absolute;
        inset: 0;
      }

      .close-modal {
        position: absolute;
        top: 40px;
        right: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 99;
      }

      @media (max-width: 768px) {
        .modal-content {
          //padding: 0 20px;

          iframe {
            width: 90vw;
            height: 60vh;
          }

          .close-modal {
            top: 80px;
            right: 20px;
          }
        }
      }
      @media (max-width: 550px) {
        .modal-content iframe {
          width: 90vw;
          height: 40vh;
        }
      }
    }
    
    
    //about us CTA
    .about-us__cta{
      position: relative;
      overflow: hidden;
      padding-top: calc(600 / 1366 * 100%);
    }


    .gph_modal .modal-dialog {
      margin: 0px;
      width: 100vw;
      height: 100%;
      background: #ffffff;
    }
    
    //Director Popup
    .popup-version-one {
        
        overflow: hidden !important;
      
      .modal-content{
        width: 100vw !important;
      }
      
      .for-close{
        align-items: center;
        padding-top: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000;
        margin-bottom: 60px;
        cursor: pointer;
      }
      .modal-close {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #000000;
        position: relative;
        overflow: hidden;
        transition: .3s ease;
        
        @media(max-width: 767px){
          height: 36px;
        }

        svg {
         
        }

        &:after {
          content: '';
          position: absolute;
          height: 0;
          width: 0;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: ${hover};
          transition: all .5s ease;
          border-radius: 19px;
        }

        &:hover {
          border-color: ${hover};

          svg{
            position: relative;
            z-index: 999;
              g{
                  line{
                      stroke: #ffffff !important;
                  }
              }
          }

          &:after {
            width: 100%;
            height: 100%;
          }
        }
      }
      
      .modal-body{
        padding-bottom: 60px;
          .sub-title{
              p{
                  margin-bottom: 0 !important;
              }
          }
        .modal-data{
          width: 100%;
          flex-wrap: wrap;
          
          &__content{
            padding-right: 70px;
            
            
            form{

              input:-webkit-autofill,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              textarea:-webkit-autofill,
              textarea:-webkit-autofill:hover,
              textarea:-webkit-autofill:focus,
              select:-webkit-autofill,
              select:-webkit-autofill:hover,
              select:-webkit-autofill:focus {
                //border: 1px solid rgba(0, 0, 0, 0);
                //-webkit-text-fill-color: #000000;
                //-webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
                //transition: background-color 5000s ease-in-out 0s;
                  border-bottom: 1px solid rgba(0 , 0 , 0 , .5);
                  -webkit-text-fill-color: #137EC3;
                  -webkit-box-shadow: none;
              }
                
              
              .from-group {
                  width: 100% !important;
                margin-bottom: 30px;
                gap: 30px;
                &:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }

                .error-message{
                    font-size: 12px;
                    line-height: 16px;
                    color: red;
                }
                .input-container{
                    width: 100% !important;
                }
              .form-control {
                
                background-color: transparent;
                border: none;
                border-bottom: 1px solid rgba(0 , 0 , 0 , .5);
                padding-left: 0;
                height: 34px;
                color: #137EC3;
                text-indent: 0;
                font-size: 16px;
                &::placeholder {
                  font-size: 16px;
                  line-height: 20px;
                  color: rgba(0 , 0 , 0 , .5);
                }
                
                &:focus{
                  border-bottom: 1px solid #137EC3;
                }
              }
              
              .form-control-lg{
                padding: 0;
              }
              textarea {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                background-color: transparent;
                border-bottom: 1px solid rgba(0 , 0 , 0 , .5);
                //border-radius: 15px;
                height: 118px;
                max-height: 80px;
                min-height: 118px;
                color: #137EC3;;
                box-shadow: none;
                outline: none;
                padding-left: 15px;

                &::placeholder {
                  font-size: 14px;
                  color: rgba(0 , 0 , 0 , .5);

                }
              }

              .buttons{
                margin-top: 30px;
                gap: 30px;
              }
              .gph_upload {
                position: relative;
                cursor: pointer;
                border: none !important;
                //border-radius: 25px !important;
                overflow: hidden !important;
                padding: 14px 44px;
                text-indent: 0 !important;
                border-radius: 25px;
                height: 47px !important;
                max-width: 175px !important;
                &.hide {
                  &:before {
                    opacity: 0;
                  }
                }

                &:after {
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  top: 0;
                  content: attr(text);
                  border: 1px dashed #137EC3;
                  border-radius: 24px;
                  border-style: dashed;
                  background: #ffffff;
                  z-index: 1;
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 500;
                  color: #137EC3;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: 0.7s all ease;
                  //padding-left: 20px;
                }


                @media (min-width: 1024px) and (max-width: 1280px) {
                  &:after {
                    //padding-left: 20px;
                  }
                }


                &:hover {
                  &:after {
                    border: 1px ${hover};
                    //border-radius: 25px;
                    background: ${hover};
                    color: #ffffff;
                  }
                }
              }
              
              @media(max-width: 767px){
                padding-right: 15px;
                .from-group{
                  &.d-flex{
                    flex-direction: column;
                  }
                }
                
                .buttons{
                  flex-direction: column;
                  input{
                    max-width: 100% !important;
                  }
                  
                  .dc-btn{
                    a{
                      width: 100% !important;
                    }
                  }
                }
              }
            }
          }
          
          &__img{
            .image-wrapper{
              position: relative;
              padding-top: calc(470 / 470 * 100%);
              overflow: hidden;
              border-radius: 0 50px;
              margin-bottom: 20px;
            }
            h5{
              margin-bottom: 10px;
            }
            p{
              color: ${hover};
            }
          }
        }
      }

      @media (max-width: 767px) {
        
        .bar{
          padding-left: 0;
        }
        
        .for-close{
          margin-bottom: 30px;
            gap: 20px;
        }
        .modal-content {
          overflow-x: hidden;
        }

        .modal-close {
          right: 15px !important;
        }

        .modal-data {
          flex-wrap: wrap;
            flex-direction: column-reverse;

          .modal-data__img {
            padding-left: 0;
            min-width: 100%;
          }
        }

        .modal-data__content {
          padding-left: 0;
          //padding-left: 15px;
          //min-width: 100%;
          //margin: 20px 0 100px 0;
          padding-right: 15px !important;
          padding-bottom: 40px;
        }
      }

    }
    
    .popup-version-one-exit{
        opacity: 1;
        transform: translateY(0);

        .custom-modal-exit-active {
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }
    .popup-version-one-exit-active{
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 1s ease, transform 1s ease;
    }
    
    //Feature Popup
    .feature{
      .modal-data{
        &__content{
          h3{
            color: #137EC3;
            margin-bottom: 30px;
          }
        }
      }
    }
    
    
    //reveal Image
    
    .reveal-img{
        overflow: hidden;
    }

    .grecaptcha-badge{
        opacity: 0 !important;
    }



    .reveal-img {
        will-change: transform;
        contain: content;
    }

    .fade-up {
        will-change: transform;
        contain: content;
    }
`;



